/* global PCP_HEADER_IMGS, PCP_MOBILE_HEADER_IMGS */

(function() {
  /** Initialise page */
  function init() {
    pickNavigation();
    startBgLoop(5);
  }

  /** Show different navigation based on where the user comes from
   *
   * From any url that contains 'duunitori' show duunitori-navigation
   * Otherwise show pcp navigation
   */
  function pickNavigation() {
    showEl(selectNav());

    function selectNav() {
      // Get nav elements
      var dtNav = document.querySelector('.js-duunitori-nav');
      var pcpNav = document.querySelector('.js-pcp-nav');

      if (!dtNav && !pcpNav) return;

      // If previous page contains duunitori pick duunitori nav
      // No fallback necessary
      if (document.referrer && document.referrer.includes('duunitori')) return dtNav;

      // Otherwise go with pcp nav
      return pcpNav;
    }

    function showEl(el) { el.style.display = 'block' }
  }


  /** Crossfade background every X seconds
   *
   * @param {number} intervalLength after how many seconds should bg change
   */
  function startBgLoop(intervalLength) {
    var desk_bg = document.querySelector('.pcp__header__background');
    var mob_bg = document.querySelector('.pcp__header');

    var toChange = setToChange();

    if (!toChange) return;

    // Split images to an array of urls
    toChange.imgs = toChange.imgs.split(',');

    // Change bg every intervalLength seconds
    setInterval(changeBg, intervalLength * 1000);
    window.addEventListener('resize', function() {
      toChange.el.style.backgroundImage = '';
      toChange = setToChange();
      toChange.imgs = toChange.imgs.split(',');
    });

    function changeBg() {
      toChange.i += 1;
      // Start from the beginning if gone over every img
      if (toChange.i === toChange.imgs.length) toChange.i = 0;

      toChange.el.style.backgroundImage = 'url(' + toChange.imgs[toChange.i] + ')';
    }

    function setToChange() {
      if (window.innerWidth > 1023 && desk_bg && PCP_HEADER_IMGS) {
        return {
          'imgs': PCP_HEADER_IMGS,
          'el': desk_bg,
          'i': 0,
        }
      }

      if (window.innerWidth < 1024 && mob_bg && PCP_MOBILE_HEADER_IMGS) {
        return {
          'imgs': PCP_MOBILE_HEADER_IMGS,
          'el': mob_bg,
          'i': 0,
        }
      }

      return null;
    }
  }

  init();
}());
